import * as React from "react"
import { graphql } from "gatsby"

import Layout from "@components/layout/layout"
import Hero from "@components/hero"
import TextImageList from "@components/textImageList"
import Logos from "@components/logos"
import Quote from "@components/quote"
import CtaSection from "@components/ctaSection"

const IndexPage = ({ data }: any) => {
  const { heroData, logosData, quoteData, contentData } = data.pageData

  return (
    <Layout transparentHeader>
      <Hero {...heroData} />
      <TextImageList {...contentData} />
      <Logos {...logosData} />
      <Quote {...quoteData} />
      <CtaSection {...data.ctaSectionData} />
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    pageData: homepageJson {
      heroData: hero {
        title
        text
        bottomLine
      }
      contentData: content {
        items {
          title
          text
          tags
          imageUrl
        }
      }
      logosData: logos {
        title
        items {
          name
          url
          imageUrl
        }
      }
      quoteData: quote {
        text
        author
        authorDesc
      }
    }
    ctaSectionData: commonJson {
      title
      text
      cta {
        text
        url
      }
    }
  }
`
