import * as React from "react"

import Container from "@components/elements/container"
import Wysiwyg from "@components/elements/wysiwyg"

import styles from "./textImageList.module.scss"

interface textImageListProps {
  items: Array<{
    title: string
    text: string
    imageUrl: string
  }>
}

const textImageList = ({ items }: textImageListProps) => {
  if (!items.length) {
    return null
  }

  return (
    <>
      <section className={styles.textImageList}>
        <Container className={styles.container}>
          <div className={styles.list}>
            {items.map(item => {
              return (
                <div className={styles.item} key={item.title}>
                  <img
                    className={styles.itemPicture}
                    srcSet={`${item.imageUrl} 1x , ${item.imageUrl} 2x`}
                    alt=""
                    loading="lazy"
                  />
                  <div className={styles.itemTitle}>{item.title}</div>
                  {item.text && (
                    <Wysiwyg
                      className={styles.itemText}
                      contentHtml={item.text}
                    />
                  )}
                  {item?.tags && (
                    <div className={styles.itemTags}>
                      {item?.tags?.map(tag => {
                        return (
                          <div className={styles.itemTag} key={tag}>
                            {tag}
                          </div>
                        )
                      })}
                    </div>
                  )}
                </div>
              )
            })}
          </div>
        </Container>
      </section>
    </>
  )
}

export default textImageList
