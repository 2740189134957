import * as React from "react"
import parse from "html-react-parser"

import Container from "@components/elements/container"

import styles from "./hero.module.scss"

interface HeroProps {
  title: string
  text: string
  bottomLine: string
}

const Hero = ({ title, text, bottomLine }: HeroProps) => {
  return (
    <section className={styles.hero}>
      <Container className={styles.container}>
        <h1 className={styles.title}>{parse(title, { trim: true })}</h1>

        {text && <div className={styles.text}>{text}</div>}

        {bottomLine && <div className={styles.bottomLine}>{bottomLine}</div>}
      </Container>
    </section>
  )
}

export default Hero
