import * as React from "react"

import Container from "@components/elements/container"
import Link from "@components/elements/link"
import { ConditionalWrapper } from "@lib/utils"

import styles from "./logos.module.scss"

interface LogosProps {
  title?: string
  items: Array<{
    name: string
    url?: string
    imageUrl: string
  }>
}

const Logos = ({ title, items }: LogosProps) => (
  <section className={styles.logos}>
    <Container className={styles.container}>
      {title && <h2 className={styles.title}>{title}</h2>}

      <div className={styles.list}>
        {items?.map(item => {
          return (
            <ConditionalWrapper
              key={item.name}
              condition={!!item.url}
              wrapper={wrapChildren => {
                return (
                  <Link className={styles.item} url={item.url}>
                    {wrapChildren}
                  </Link>
                )
              }}
              elseWrapper={wrapChildren => (
                <div className={styles.item}>{wrapChildren}</div>
              )}
            >
              <img
                src={item.imageUrl}
                srcSet={`${item.imageUrl} 2x`}
                alt={`${item.name} logo`}
                loading="lazy"
              />
            </ConditionalWrapper>
          )
        })}
      </div>
    </Container>
  </section>
)

export default Logos
